<template>
    <div class="content">
        <img class="ribbon" src="./imgs/ribbon.png" alt="">
        <div class="t-bg">
            <img class="title-bg" src="./imgs/title.png" alt="">

            <!-- 用户名称提示 -->
            <div class="use-tips">
                {{ merchantName }} 您好， <span class="luck">祝好运！</span>
            </div>

            <van-swipe :show-indicators="false" :vertical="true" :touchable="false" :autoplay="1000">
                <van-swipe-item v-for="(item, index) in winningList" :key="index">
                    <div :class="['winning-item', subIndex == 2 ? 'winning-item winning-item-bom' : '']"
                        v-for="(subItem, subIndex) in item.phoneNumbers" :key="subIndex">
                        {{ subItem.phoneNumber }}，抽中 <span class="">{{ subItem.randomNumber }}</span> 元红包！
                    </div>
                </van-swipe-item>
            </van-swipe>

            <div class="t-choujiang t-flex-row" :style="{
                    width: `${rulette.radius}px`,
                    height: `${rulette.radius}px`,
                }">
                <LuckyWheel class="luck-draw" ref="LuckyWheel" :width="325" :height="325" :defaultConfig="defaultConfig"
                    :buttons="buttons" :blocks="blocks" :prizes="prizeList" @end="luckyEnd" />
            </div>
            <div class="base-box">
                <img class="base-img" src="./imgs/base.png" alt="">
            </div>
            <div class="t-draw t-flex-row" @click="start">
                <!-- -->
                <span :class="[!turning ? 'scale' : '']">立即抽奖</span>
            </div>

        </div>
        <div class="t-chance t-flex-row-sa"> <span>已抽奖次数：{{ drawCount }} 次</span> <span>可抽奖次数: {{ chance
                }}次</span> </div>
        <!-- 规则部分 -->
        <div class="t-bottom">
            <img class="coin" src="./imgs/coin.png" alt="">
            <div class="t-b-title">抽奖规则</div>
            <div class="t-luck-wrapper" v-html="rules"></div>
        </div>

        <!-- 抽奖结束弹窗 -->
        <kevy-mask :show="isShowAwd" @click="toConfirmAwd">

            <!-- 中奖弹窗 -->
            <div class="winning" v-if="lotteryType === 0">
                <img class="winning-img" src="./imgs/Winning.png" alt="">
                <div class="winning-box">
                    <div class="winning-tips">恭喜您！中奖啦</div>
                    <div class="money-box">
                        <span class="money">{{ ainningAmount }}</span>
                        <span class="money-tips">红包</span>
                    </div>
                    <div @click="toConfirmAwd" class="t-tk-btn t-bg-full">
                        <span>确定</span>
                    </div>
                </div>
            </div>

            <!-- 抽奖次数已用完 -->
            <div class="warmTips"
                v-if="(maskType === 1 || maskType === 3 || maskType === 4 || maskType === 5 || maskType === 6) && lotteryType === null">
                <div class="warmTips-title">温馨提示</div>
                <div class="warmTips-tips"> {{ tipsTxt[maskType] }} </div>
                <div @click="toConfirmAwd" class="t-tk-btn t-bg-full">
                    <span>确定</span>
                </div>
            </div>

            <!-- 谢谢参与弹窗 -->
            <div class="Thankyou" v-if="lotteryType === 1">
                <img class="Thankyou-img" src="./imgs/smiling-bg.png" alt="">
                <div class="Thankyou-box">
                    <div class="Thankyou-tips"> 谢谢参与 </div>
                    <div @click="toConfirmAwd" class="t-tk-btn t-bg-full">
                        <span>确定</span>
                    </div>
                </div>
            </div>

            <!-- 温馨提示 -->
            <!-- <div class="warmTips" v-if="false">
                <div class="warmTips-title">温馨提示</div>
                <div class="warmTips-tips" v-if="false"> 今日礼品已派送完毕 </div>
                <div class="warmTips-tips" v-else> 今日抽奖次数已用完 </div>
                <div @click="toConfirmAwd" class="t-tk-btn t-bg-full">
                    <span>确定</span>
                </div>
            </div> -->

            <!-- 谢谢参与弹窗 -->
            <div class="expire" v-if="maskType === 2">
                <img class="expire-img" src="./imgs/expire.png" alt="">
                <div class="expire-box">
                    <div class="expire-title"> 抽奖活动已结束 </div>
                    <div class="expire-tips"> 敬请期待下次活动！ </div>
                    <div @click="toConfirmAwd" class="t-tk-btn t-bg-full">
                        <span>确定</span>
                    </div>
                </div>
            </div>

        </kevy-mask>
    </div>
</template>

<script>
let LuckyWheel;
if (process.browser) {
    LuckyWheel = require('vue-luck-draw').LuckyWheel;
}

import { getListofWinners, getDrawward, getKeys } from "@/api/index";
import {
    Toast
} from "vant";
import kevyMask from "./kevy-mask.vue";
import * as CryptoUtils from '@/utils/encryption.js';

export default {
    components: {
        kevyMask,
        LuckyWheel
    },
    data() {
        return {
            // 转盘配置
            defaultConfig: {
                gutter: 0,
                stopRange: 0.5,
                offsetDegree: 0
            },
            // 转盘配置
            buttons: [{
                imgs: [{
                    src: require('./imgs/start.png'),
                    width: 94,
                    height: 94,
                    top: -(94) / 2,
                }]
            }],
            // 转盘配置
            blocks: [{
                padding: '30px', imgs: [{
                    src: require('./imgs/bg.png'),
                    width: 325,
                    height: 325,
                    rotate: true,
                }]
            }],
            // 转盘配置
            rulette: {
                radius: 650 / 2, // 转盘半径
                innnerTimes: 0.78, //转盘内圆大小
                errorRange: 20, // 匀速中奖角度偏离大小
                turns: 10, // 转圈数
            },
            turning: false, //转盘是否正在转
            isShowAwd: false, //是否显示奖品弹框，抽奖后提示，要么中奖奖品，要么谢谢参与
            // timer: null,
            prizeList: [], // 奖品列表
            ainningAmount: 0, // 中奖金额
            merchantName: "", // 商户名称
            winningList: [], // 中奖名单， 前端随机拼成
            rules: "", // 规则
            chance: 0, // 可抽奖次数
            drawCount: 0, // 已抽奖次数
            orderNo: "", // 活动单号
            maskType: null, // 弹窗类型 0：可抽奖，1：该红包已抽完，2：活动已结束，3：今日抽奖次数已用完，4：本月抽奖次数已用完，5：活动已过期，6：活动已停用
            lotteryType: null, // 中奖状态： 0 中奖， 1、谢谢参与
            tipsTxt: ['', '该红包已抽完', '活动已结束', '今日抽奖次数已用完', '本月抽奖次数已用完', '活动已过期', '活动已停用'],
            privateKey: "",  // 私钥
            publicKey: "", // 公钥

        };
    },
    async mounted() {
        // 获取 密钥
        const keys = await getKeys()
        this.privateKey = keys.data.privateKey
        this.publicKey = keys.data.publicKey

        if (!this.$route.query.orderNo) {
            Toast({
                message: "活动信息获取异常",
                duration: 2000
            });
            return;
        }
        this.orderNo = this.$route.query.orderNo

        Toast.loading({
            message: '活动加载中...',
            forbidClick: true,
        });
        this.getHistoryList()

        console.log("winningList", this.winningList);
    },
    methods: {
        // 随机中奖名单
        generateRandomPhoneNumber() {
            const prefixes = ['130', '131', '132', '133', '134', '135', '136', '137', '138', '139',
                '150', '151', '152', '153', '155', '156', '157', '158', '159',
                '170', '171', '172', '173', '175', '176', '177', '178', '179',
                '180', '181', '182', '183', '184', '185', '186', '187', '188', '189',
                '190', '191', '192', '193', '195', '196', '197', '198', '199'];
            const prefix = prefixes[Math.floor(Math.random() * prefixes.length)];
            const suffix = Math.floor(Math.random() * 100000000).toString().padStart(8, '0');
            return prefix + suffix;
        },
        maskPhoneNumber(phoneNumber) {
            return phoneNumber.slice(0, 3) + '****' + phoneNumber.slice(7);
        },
        getRandomNumber(arr) {
            if (arr.length === 0) {
                throw new Error("Array is empty");
            }
            let randomIndex = Math.floor(Math.random() * arr.length);
            return arr[randomIndex];
        },
        generateRandomData(arr) {
            return Array.from({ length: 9 }, () => ({
                phoneNumbers: Array.from({ length: 3 }, () => ({
                    phoneNumber: this.maskPhoneNumber(this.generateRandomPhoneNumber()),
                    randomNumber: this.getRandomNumber(arr)
                }))
            }));
        },

        // 获取中奖将列表
        getHistoryList(flag) {
            const data = {
                orderNo: this.orderNo
            }
            const resulrObj = CryptoUtils.gerResult(this.publicKey, this.privateKey, JSON.stringify(data), "app/sysLotteryActivity/getPrizeRewardInfo")
            getListofWinners(resulrObj).then(res => {
                console.log(res)
                if (res.code != 200) {
                    Toast.clear();
                    Toast(res.msg)
                    return
                }
                // this.$refs.LuckyWheel.init();
                this.merchantName = `${res.data.merchantName}, ${res.data.merchantPhone}, `
                this.rules = res.data.ruleRemark.replace(/ /g, '&nbsp;').replace(/\n/g, '<br>')
                this.chance = res.data.canDrawCount;
                this.drawCount = res.data.drawCount;
                this.maskType = res.data.statusCode
                if (this.maskType !== 0) {
                    this.isShowAwd = true
                }

                const arr = []

                this.prizeList = []
                res.data.prizes.map((item, idx) => {
                    arr.push(item.prizeMoney)
                    switch (idx % 3) {
                        case 0:
                            this.prizeList.push({ id: item.id, fonts: [{ text: item.prizeName, top: '16', fontColor: '#E01F21', fontSize: '14px' }], imgs: [{ src: item.imageUrl, top: "45", width: "34px", height: "34px" }], background: '#ffffff' })
                            break;
                        case 1:
                            this.prizeList.push({ id: item.id, fonts: [{ text: item.prizeName, top: '16', fontColor: '#E01F21', fontSize: '14px' }], imgs: [{ src: item.imageUrl, top: "45", width: "34px", height: "34px" }], background: '#FFDBB1' })
                            break;
                        case 2:
                            this.prizeList.push({ id: item.id, fonts: [{ text: item.prizeName, top: '16', fontColor: '#E01F21', fontSize: '14px' }], imgs: [{ src: item.imageUrl, top: "45", width: "34px", height: "34px" }], background: '#feedd8' })
                            break;
                    }
                })
                this.winningList = this.generateRandomData(arr);
                if (!flag) {
                    Toast.clear();
                }
            })
        },
        /**
         * 抽奖按钮点击
         */
        start() {
            console.log("this.turning", this.turning);
            if (this.turning) return;

            if (!this.orderNo) {
                Toast({
                    message: "活动信息获取异常",
                    duration: 2000
                });
                return;
            }

            if (!this.prizeList.length) {
                Toast({
                    message: "暂无奖池信息",
                    duration: 2000
                });
                return;
            }

            if (this.maskType !== 0) {
                this.isShowAwd = true
                return
            }

            Toast.loading({
                message: '请求中...',
                forbidClick: true,
                loadingType: 'spinner',
            });

            //开始转动，若无结果，一直转动
            this.turning = true;
            const data = { orderNo: this.orderNo }

            const resulrObj = CryptoUtils.gerResult(this.publicKey, this.privateKey, JSON.stringify(data), 'app/sysLotteryActivity/prizeReward')

            getDrawward(resulrObj).then(res => {
                if (res.code !== 200) {
                    this.$refs.LuckyWheel.stop(0);
                    setTimeout(() => {
                        Toast({
                            message: res.msg || "请求失败",
                            duration: 5000,
                            forbidClick: true,
                            onOpened: () => {
                                setTimeout(() => {
                                    this.turning = false;
                                }, 5000)
                                this.getHistoryList(true)
                            }
                        });
                    })
                } else {
                    Toast.clear();
                    this.$refs.LuckyWheel.play();
                    if (res.data.statusCode === 0) {
                        this.lotteryType = 0
                    } else if (res.data.statusCode === 1) {
                        this.lotteryType = 1
                    }
                    const Winning = this.prizeList.findIndex(item => item.id == res.data.id)
                    if (Winning == -1) {
                        Toast({
                            message: "出错啦，请重新抽奖",
                            duration: 2000
                        })
                        this.$refs.LuckyWheel.stop(0);
                    } else {
                        this.$refs.LuckyWheel.stop(Winning);
                        this.ainningAmount = res.data.prizeMoney
                    }
                }
            })
        },

        /**
         * 结束后动画
         */
        luckyEnd(prize) {
            this.$refs.LuckyWheel.init();
            this.getHistoryList() // 重置抽奖记录
            this.turning = false;
            this.isShowAwd = true;
        },

        /**
         * 确认奖品按钮点击事件
         */
        toConfirmAwd() {
            //这里中奖信息关闭弹框
            this.isShowAwd = false;
            this.turning = false;
            // this.maskType = null;
            this.lotteryType = null;
        },
    },
};
</script>

<style scoped lang="scss">
.content {
    width: 100%;
    min-height: 100vh;
    padding-top: 1px;
    box-sizing: border-box;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-image: url(./imgs/lottery-bg.png);
    background-color: #e92b07;
    overflow: scroll;

    .ribbon {
        width: 100%;
        position: absolute;
        z-index: 1;
    }
}

.t-bg {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    overflow-x: hidden;
    box-sizing: border-box;
    text-align: center;
    padding-bottom: 15px;
    margin-bottom: 15px;

    .title-bg {
        width: 278px;
        height: 75px;
        margin: 20px 0px 0;
    }

    .use-tips {
        width: 260px;
        height: 33px;
        margin: 0 auto 45px;
        text-align: center;
        line-height: 33px;
        font-weight: bold;
        font-size: 13px;
        color: #FF9F1E;
        background: #FFFFFF;
        border-radius: 2px 17px 17px 17px;
        background: linear-gradient(262deg, #FDEFD2 0%, #FFFFFF 100%);
    }

    .van-swipe {
        position: absolute;
        top: 140px;
        right: 10px;
        width: 200px;
        height: 60px;
        text-align: right;
        // background-color: ;

        .van-swipe-item {
            height: 60px;


            .winning-item {
                font-size: 12px;
                color: #FFFEF8;

                span {
                    color: #FFFEB5;
                }
            }

            .winning-item-bom {
                color: #f5a49c;
            }
        }
    }

    .base-box {
        margin-top: -20px;
        z-index: 0;

        .base-img {
            width: 340px;
            height: 109px;
        }
    }

    .t-draw {
        position: absolute;
        bottom: 0px;
        left: 50%;
        width: 300px;
        height: 60px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-image: url(./imgs/anniu.png);
        font-size: 22px;
        color: #ffffff;
        transform: translateX(-50%);
        z-index: 9;

        .scale {
            animation: scale 0.8s infinite ease-in-out;
        }
    }



    @keyframes scale {

        0%,
        100% {
            transform: scale(1);
        }

        50% {
            transform: scale(1.2);
        }
    }
}

// 中奖
.winning {
    width: 280px;

    .winning-img {
        width: 280px;
        height: 197px;
    }

    .winning-box {
        margin-top: -10px;
        padding-bottom: 20px;
        background: linear-gradient(181deg, #FCF3F4 0%, #FFFFFF 100%);
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;

        .winning-tips {
            margin-top: -55px;
            text-align: center;
            font-weight: bold;
            font-size: 18px;
            color: #CE292F;
        }

        .money-box {
            text-align: center;
            margin: 20px auto;

            .money {
                font-weight: bold;
                font-size: 56px;
                color: #E73A2A;
            }

            .money-tips {
                font-weight: bold;
                font-size: 28px;
                color: #E73A2A;
            }
        }

    }
}

// 谢谢参与
.Thankyou {
    position: relative;
    width: 280px;
    height: 160px;
    padding-top: 40px;
    background: #FFFFFF;
    border-radius: 15px;

    .Thankyou-img {
        position: absolute;
        top: -40px;
        left: 50%;
        width: 80px;
        height: 80px;
        transform: translateX(-50%);
    }

    .Thankyou-tips {
        margin: 30px auto 30px;
        text-align: center;
        font-weight: bold;
        font-size: 18px;
        color: #CE292F;
    }
}

// 温馨提示
.warmTips {
    position: relative;
    width: 280px;
    height: 180px;
    padding: 10px;
    background: #FFFFFF url("./imgs/warmTips.png") no-repeat;
    background-size: 280px 77px;
    border-radius: 15px;

    .warmTips-img {
        position: absolute;
        width: 280px;
        height: 77px;
    }

    .warmTips-title {
        margin: 20px auto 15px;
        text-align: center;
        font-weight: bold;
        font-size: 18px;
        color: #CE292F;
    }

    .warmTips-tips {
        font-size: 16px;
        text-align: center;
        color: #333333;
        margin-bottom: 35px;
    }
}

// 谢谢参与
.expire {
    position: relative;
    width: 280px;
    border-radius: 15px;


    .expire-img {
        width: 280px;
        height: 200px;
    }

    .expire-box {
        margin-top: -10px;
        padding-bottom: 20px;
        background: #FFFFFF;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
    }

    .expire-title {
        text-align: center;
        font-weight: bold;
        font-size: 18px;
        color: #999999;
        margin-top: -50px;
    }

    .expire-tips {
        margin: 15px auto 30px;
        text-align: center;
        font-size: 18px;
        color: #E73A2A;
    }
}


// 弹窗确认
.t-tk-btn {
    width: 235px;
    height: 50px;
    line-height: 50px;
    margin: 15px auto 0px;
    text-align: center;
    font-size: 24px;
    color: #ffffff;
    background: linear-gradient(180deg, #ED111B 0%, #FF8632 100%);
    box-shadow: 2px 10px 10px 1px rgba(255, 14, 14, 0.27);
    border-radius: 60px 60px 60px 60px;
}

.t-chance {
    margin: 0 40px 15px 40px;
    // margin-bottom: 15px;
    text-align: center;
    font-size: 16px;
    color: #FFFEF8;
    color: #ffffff;
}

.t-choujiang {
    margin: 0px auto;
    position: relative;
}

.rulette {
    position: absolute;
    z-index: 3;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    display: flex;
    background: linear-gradient(180deg, #fffcf1 0%, #fff6d2 100%);
}

.rulette-item {
    position: absolute;
    left: 50%;
    top: 0;
    height: 50%;
    z-index: 4;
    transform-origin: 0 100%;
    text-align: center;
}

.rulette-item.noPrize .rulette-item-text {
    font-size: 21px;
    width: 60px;
    font-weight: 600;
    margin-top: 16px;
}

.rulette-item-bar {
    position: absolute;
    left: 50%;
    top: 0;
    z-index: 4;
    width: 5px;
    height: 100%;
    transform-origin: 0 100%;
    background-color: #fd8b2f;
}

.rulette-item-img {
    position: absolute;
    left: 50%;
    top: 45px;
    z-index: 5;
    transform: translate(-50%, -50%);
    width: 60%;
    max-width: 77px;
}

.rulette-item-text {
    font-weight: 600;
    position: absolute;
    left: 50%;
    top: 32.5px;
    z-index: 5;
    transform: translate(-50%, -50%);
    font-size: 16px;
    color: #e02020;
}

.rulette-item-random {
    font-weight: 500;
    position: absolute;
    left: 50%;
    top: 65px;
    z-index: 99;
    transform: translate(-50%, -50%);
    font-size: 14px;
    color: #fff;
}

.t-bottom {
    position: relative;
    margin: 0 10px 20px;
    box-sizing: border-box;
    padding: 18px 14px;
    background-size: 100% 15px;
    background-position: left 0 bottom 2px;
    background-repeat: no-repeat;
    margin-bottom: 20px;
    background-color: #ffeddf;
    border-radius: 6px;

    .coin {
        position: absolute;
        top: 0;
        right: 0;
        width: 60px;
        height: 50px;
    }

    .t-b-title {
        font-weight: bold;
        font-size: 16px;
        color: #CE292F;
        margin-bottom: 10px;
    }

    .t-luck-wrapper {
        font-size: 13px;
        color: #751A0B;
        line-height: 20px;
    }
}
</style>

<style scoped>
.t-flex-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 9;
}

.t-flex-row-sa {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.t-flex-row-sb {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.t-flex-row-s {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.t-flex-row-s-s {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

.t-flex-row-s-e {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
}

.t-flex-row-e {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    line-height: 30px;
}

.t-flex-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.t-flex-col-sa {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.t-flex-col-sb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.t-flex-col-s {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.t-flex-col-s-s {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.t-flex-col-s-e {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
}

.t-flex-col-e {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}
</style>
